import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto"},[_c(VToolbar,{attrs:{"color":"blue lighten-2","cards":"","dark":"","flat":""}},[_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" Resum diari "),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"blue lighten-2","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu")])],1)]}}])},[_c(VList,_vm._l((_vm.menuItems),function(item,index){return _c(VListItem,{key:index},[_c(VListItemIcon,[_c(VIcon,{on:{"click":function($event){return _vm.goMenu(item.action)}}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,{on:{"click":function($event){return _vm.goMenu(item.action)}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c(VForm,{ref:"dialogDataForm",staticClass:"pa-4 pt-6",model:{value:(_vm.dialogDataForm),callback:function ($$v) {_vm.dialogDataForm=$$v},expression:"dialogDataForm"}},[_c(VDialog,{ref:"dialogDataRef",attrs:{"return-value":_vm.dialogDataValue,"persistent":"","width":"300px"},on:{"update:returnValue":function($event){_vm.dialogDataValue=$event},"update:return-value":function($event){_vm.dialogDataValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VTextField,_vm._g(_vm._b({attrs:{"rules":[_vm.rules.length(1)],"label":"Data","prepend-icon":"mdi-calendar","readonly":""},on:{"change":function($event){return _vm.getTasks()}},model:{value:(_vm.selDate),callback:function ($$v) {_vm.selDate=$$v},expression:"selDate"}},'v-text-field',attrs,false),on))],1),_c(VCol,[_c(VBtn,{staticClass:"ma-2",on:{"click":function($event){return _vm.dataAdd(1)}}},[_c(VIcon,[_vm._v("mdi-plus-circle-outline")])],1),_c(VBtn,{staticClass:"ma-2",on:{"click":function($event){return _vm.dataAdd(-1)}}},[_c(VIcon,[_vm._v("mdi-minus-circle-outline")])],1)],1)],1)]}}]),model:{value:(_vm.dialogDataModel),callback:function ($$v) {_vm.dialogDataModel=$$v},expression:"dialogDataModel"}},[_c(VDatePicker,{attrs:{"locale":"es"},model:{value:(_vm.selDate),callback:function ($$v) {_vm.selDate=$$v},expression:"selDate"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"blue lighten-2"},on:{"click":function($event){_vm.dialogDataModel = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"blue lighten-2"},on:{"click":function($event){_vm.$refs.dialogDataRef.save(_vm.dialogDataValue);
        _vm.getTasks();}}},[_vm._v("OK ")])],1)],1)],1),_c(VDivider),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Ordre")]),_c('th',{staticClass:"text-left"},[_vm._v("Client")]),_c('th',{staticClass:"text-right"},[_vm._v("Temps")]),_c('th',{staticClass:"text-center"},[_vm._v("Edit")])])]),_c('tbody',[_vm._l((_vm.taskList),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.calcDoc))]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.calcCustomerName)+" "+_vm._s(_vm.getTittle(item))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.calcMinutes))]),_c('td',{staticClass:"text-center"},[_c(VIcon,{on:{"click":function($event){return _vm.goEditTime(item)}}},[_vm._v("mdi-pencil")])],1)])}),_c('tr',[_c('td',{staticClass:"text-left"}),_c('td',{staticClass:"text-left"},[_vm._v("Total")]),_c('td',{staticClass:"text-right",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.totalTime))])])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }