//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import moment from "moment";
import api from "../api.js";
export default {
  data: () => ({
    menuItems: [{ icon: "mdi-exit-to-app", title: "Menu", action: "gomenu" }],
    dialogDataForm: false,
    dialogDataModel: false,
    dialogDataRef: false,
    dialogDataValue: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substring(0, 10),
    selDate: "",
    totalTime: "",
    taskList: [],
    rules: {
      length: (len) => (v) =>
        (v || "").length >= len || `Invalid character length, required ${len}`,
      required: (v) => !!v || "This field is required",
    },
  }),
  mounted() {
    if (
      this.$route.query.init == "true" ||
      sessionStorage.getItem("sessWorkDaySummary_SelDate") == ""
    ) {
      this.selDate = this.formatDate(Date.now());
    } else {
      this.selDate = sessionStorage.getItem("sessWorkDaySummary_SelDate");
    }
    sessionStorage.setItem("sessWorkDaySummary_SelDate", this.selDate);
    //this.selDate = this.formatDate(Date.now());
    this.getTasks();
  },
  methods: {
    getToday() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10);
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    goBack() {
      this.$router.push("/mainmenu");
    },
    goMenu(action) {
      switch (action) {
        case "logout":
          this.goLogOut();
          break;
        case "gomenu":
          this.$router.push("/mainmenu");
          break;
      }
    },
    dataAdd(q) {
      var d = new Date(this.selDate);
      d.setDate(d.getDate() + q);
      this.selDate = this.formatDate(d);
      sessionStorage.setItem("sessWorkDaySummary_SelDate", this.selDate);
      this.getTasks();
    },
    getTasks() {
      axios
        .post(api.URL() + "/api/v1/gordresfabricacio01_getitemsbydate", {
          token: sessionStorage.getItem("Token"),
          userId: parseInt(sessionStorage.getItem("UserId")),
          date: new Date(this.selDate),
        })
        .then((response) => {
          if (response.error == null) {
            this.taskList = response.data.Items01;
            this.totalTime = response.data.TotalTime;
          } else {
            this.msgAlert(response.error, true);
          }
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    getTittle(item) {
      var t = item.docDate + " " + item.docTime + " a ";
      if (item.endDate != "0000-00-00") {
        t = t + item.endDate + " " + item.endTime;
      } else {
        t = t + " ...";
      }
      return t;
    },
    setFocus(nomCamp) {
      setTimeout(() => {
        if (document.getElementById(nomCamp) != null) {
          document.getElementById(nomCamp).focus();
        }
      }, 200);
    },
    goEditTime(item) {
      sessionStorage.setItem("sessDocType", item.docType);
      sessionStorage.setItem("sessDocCompanyId", item.docCompanyId);
      sessionStorage.setItem("sessDocYear", item.docYear);
      sessionStorage.setItem("sessDocSerialId", item.docSerialId);
      sessionStorage.setItem("sessDocId", item.docId);
      sessionStorage.setItem("sessDocLineId", item.docLineId);
      this.$router.push("/workorder01edittime");
    },
  },
  msgAlert(missatge, close) {
    var onClose = () => {
      if (close && close == true) {
        this.$router.push("/");
      }
    };

    this.$alert.show({ message: missatge, onClose: onClose });
  },
};
